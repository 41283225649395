import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertCircle, Trash2 } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Switch } from '@/components/ui/switch';
import type { FeatureDefinition, OutputType } from '@/types/dashboard';

interface FeatureDefinitionFormProps {
  feature: FeatureDefinition;
  onChange: (feature: FeatureDefinition) => void;
  onRemove: () => void;
  disabled?: boolean;
  existingLabels?: string[];
}

const OUTPUT_TYPES: { value: OutputType; label: string }[] = [
  { value: 'string', label: 'Text String' },
  { value: 'number', label: 'Number' },
  { value: 'boolean', label: 'True/False' },
  { value: 'array', label: 'List/Array' }
];

const FeatureDefinitionForm: React.FC<FeatureDefinitionFormProps> = ({
  feature,
  onChange,
  onRemove,
  disabled,
  existingLabels = []
}) => {
  const { t } = useTranslation();
  const [labelError, setLabelError] = useState<string | null>(null);

  const validateLabel = (label: string): boolean => {
    if (!label.trim()) {
      setLabelError(t('dashboard.assistant.form.errors.labelRequired'));
      return false;
    }
    
    // Check for duplicate labels
    if (existingLabels.some(existing => existing === label && existing !== feature.label)) {
      setLabelError(t('dashboard.assistant.form.errors.labelDuplicate'));
      return false;
    }
    
    // Validate label format (alphanumeric, underscores, no spaces)
    if (!/^[a-zA-Z][a-zA-Z0-9_]*$/.test(label)) {
      setLabelError(t('dashboard.assistant.form.errors.labelFormat'));
      return false;
    }
    
    setLabelError(null);
    return true;
  };

  const handleChange = (
    field: keyof FeatureDefinition | 'sampleValue' | 'required' | 'outputType' | 'label', 
    value: string | boolean
  ) => {
    let updatedFeature = {
      ...feature,
      outputDefinition: {
        ...feature.outputDefinition,
        id: feature.outputDefinition?.id || crypto.randomUUID(),
        description: feature.description,
        outputType: feature.outputDefinition?.outputType || 'string',
        sampleValue: feature.outputDefinition?.sampleValue || '',
        required: feature.outputDefinition?.required ?? true
      }
    };

    if (field === 'label') {
      if (validateLabel(value as string)) {
        updatedFeature.label = value as string;
      } else {
        return; // Don't update if validation fails
      }
    } else if (field === 'description') {
      updatedFeature.description = value as string;
      updatedFeature.outputDefinition.description = value as string;
    } else if (field === 'sampleValue' || field === 'required' || field === 'outputType') {
      updatedFeature.outputDefinition = {
        ...updatedFeature.outputDefinition,
        [field]: value
      };
    }

    onChange(updatedFeature);
  };

  return (
    <div className="p-4 border rounded-lg space-y-4">
      <div className="flex items-start justify-between">
        <div className="space-y-4 flex-1">
          <div className="space-y-1.5">
            <Label htmlFor={`feature-label-${feature.id}`}>
              {t('dashboard.assistant.form.featureLabel')}
              <span className="ml-2 text-sm text-muted-foreground">
                (JSON response field name)
              </span>
            </Label>
            <Input
              id={`feature-label-${feature.id}`}
              value={feature.label || ''}
              onChange={(e) => handleChange('label', e.target.value)}
              placeholder="e.g., dishName, ingredientList"
              disabled={disabled}
              className={labelError ? 'border-red-500' : ''}
            />
            {labelError && (
              <Alert variant="destructive" className="mt-2">
                <AlertCircle className="h-4 w-4" />
                <AlertDescription>{labelError}</AlertDescription>
              </Alert>
            )}
          </div>
          
          <div className="space-y-1.5">
            <Label htmlFor={`feature-${feature.id}`}>
              {t('dashboard.assistant.form.featureDescription')}
              <span className="ml-2 text-sm text-muted-foreground">
                (Natural language instruction)
              </span>
            </Label>
            <Input
              id={`feature-${feature.id}`}
              value={feature.description}
              onChange={(e) => handleChange('description', e.target.value)}
              placeholder="e.g., Identify the main dish name from the image"
              disabled={disabled}
            />
          </div>
        </div>
        <Button
          variant="ghost"
          size="sm"
          onClick={onRemove}
          disabled={disabled}
          className="ml-2"
        >
          <Trash2 className="h-4 w-4" />
        </Button>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div className="space-y-1.5">
          <Label htmlFor={`output-type-${feature.id}`}>
            {t('dashboard.assistant.form.outputType')}
          </Label>
          <Select
            value={feature.outputDefinition?.outputType || 'string'}
            onValueChange={(value: OutputType) => handleChange('outputType', value)}
            disabled={disabled}
          >
            <SelectTrigger id={`output-type-${feature.id}`}>
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              {OUTPUT_TYPES.map(type => (
                <SelectItem key={type.value} value={type.value}>
                  {type.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <div className="space-y-1.5">
          <Label htmlFor={`sample-value-${feature.id}`}>
            {t('dashboard.assistant.form.sampleValue')}
          </Label>
          <Input
            id={`sample-value-${feature.id}`}
            value={feature.outputDefinition?.sampleValue || ''}
            onChange={(e) => handleChange('sampleValue', e.target.value)}
            placeholder="Example value for this field"
            disabled={disabled}
          />
        </div>
      </div>

      <div className="flex items-center space-x-2">
        <Switch
          id={`required-${feature.id}`}
          checked={feature.outputDefinition?.required ?? true}
          onCheckedChange={(checked) => handleChange('required', checked)}
          disabled={disabled}
        />
        <Label htmlFor={`required-${feature.id}`}>
          {t('dashboard.assistant.form.required')}
        </Label>
      </div>
    </div>
  );
};

export default FeatureDefinitionForm;
