import React, { useState } from 'react';
import { Plus } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useTranslation } from 'react-i18next';
import type { FeatureDefinition } from '@/types/dashboard';

interface FeatureInputProps {
  onAdd: (feature: FeatureDefinition) => void;
  disabled?: boolean;
}

const FeatureInput: React.FC<FeatureInputProps> = ({ onAdd, disabled }) => {
  const { t } = useTranslation();
  const [description, setDescription] = useState('');

  const handleAddFeature = () => {
    if (description.trim()) {
      onAdd({
        id: crypto.randomUUID(),
        description: description.trim(),
        outputType: 'string',
        sampleValue: '',
        required: true
      });
      setDescription('');
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleAddFeature();
    }
  };

  return (
    <div className="flex gap-2">
      <Input
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        onKeyPress={handleKeyPress}
        placeholder={t('dashboard.assistant.form.featureDescriptionPlaceholder')}
        className="flex-1"
        disabled={disabled}
      />
      <Button
        onClick={handleAddFeature}
        disabled={disabled || !description.trim()}
        variant="secondary"
      >
        <Plus className="h-4 w-4" />
      </Button>
    </div>
  );
};

export default FeatureInput;
