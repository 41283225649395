// src/components/dashboard/AssistantConfiguration.tsx

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Save, Trash2, AlertCircle, Copy, Check } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { useAuth } from '@/hooks/useAuth';
import { logger } from '@/utils/logger';
import type { 
  Feature, 
  FeatureType, 
  Assistant, 
  PlanFeatures,
  ApiKeyResponse,
  CreateAssistantRequest,
  APIResponse
} from '@/types/dashboard';

// Components
import AssistantDemo from './AssistantDemo';
import FeatureConfiguration from './FeatureConfiguration';
import FeatureInput from './FeatureInput';

const API_BASE_URL = '/api/dashboard';

const AssistantConfiguration: React.FC = () => {
  const { t } = useTranslation();
  const { token } = useAuth();

  // State
  const [assistants, setAssistants] = useState<Assistant[]>([]);
  const [apiKeys, setApiKeys] = useState<ApiKeyResponse[]>([]);
  const [planFeatures, setPlanFeatures] = useState<PlanFeatures | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [saveStatus, setSaveStatus] = useState('');
  const [copiedId, setCopiedId] = useState<string | null>(null);

  const [newAssistant, setNewAssistant] = useState<CreateAssistantRequest>({
    name: '',
    featureType: 'IMAGE',
    features: []
  });

  // Handlers
  const handleCopyId = async (id: string) => {
    try {
      await navigator.clipboard.writeText(id);
      setCopiedId(id);
      setTimeout(() => setCopiedId(null), 2000);
    } catch (err) {
      logger.error('Failed to copy ID:', err);
    }
  };

  const handleSaveAssistant = async () => {
    // Detailed validation
    const validationErrors: string[] = [];

    // Assistant name validation
    if (!newAssistant.name.trim()) {
      validationErrors.push("Assistant name is required");
    }

    // Features validation
    if (newAssistant.features.length === 0) {
      validationErrors.push("At least one feature must be configured");
    } else {
      newAssistant.features.forEach((feature, index) => {
        const featureNum = index + 1;
        
        // Feature label validation
        if (!feature.label?.trim()) {
          validationErrors.push(`Feature ${featureNum}: JSON response field name is required`);
        }

        // Feature description validation
        if (!feature.description?.trim()) {
          validationErrors.push(`Feature ${featureNum}: Natural language instruction is required`);
        }

        // Output type validation
        if (!feature.outputDefinition?.outputType) {
          validationErrors.push(`Feature ${featureNum}: Output type must be selected`);
        }

        // Sample value validation for required fields
        if (feature.outputDefinition?.required && !feature.outputDefinition?.sampleValue) {
          validationErrors.push(`Feature ${featureNum}: Sample value is required for required fields`);
        }

        // Type-specific validations
        if (feature.outputDefinition?.outputType === 'number' && 
            feature.outputDefinition?.sampleValue && 
            isNaN(Number(feature.outputDefinition.sampleValue))) {
          validationErrors.push(`Feature ${featureNum}: Sample value must be a valid number`);
        }
      });
    }

    if (validationErrors.length > 0) {
      setSaveStatus(validationErrors.join('\n'));
      return;
    }

    // Request body preparation with proper type handling
    const requestBody = {
      name: newAssistant.name,
      featureType: newAssistant.featureType,
      features: newAssistant.features.map(feature => ({
        label: feature.label?.trim(),
        description: feature.description.trim(),
        outputDefinition: {
          outputType: feature.outputDefinition.outputType,
          description: feature.description.trim(),
          required: feature.outputDefinition.required ?? true,
          sampleValue: feature.outputDefinition.outputType === 'number' 
            ? Number(feature.outputDefinition.sampleValue)
            : feature.outputDefinition.sampleValue
        }
      }))
    };

    try {
      setIsLoading(true);
      setSaveStatus('');

      logger.debug('Sending assistant data:', requestBody);

      const response = await fetch(`${API_BASE_URL}/assistants`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to save assistant configuration');
      }

      const result: APIResponse<Assistant> = await response.json();

      if (result.success && result.data) {
        setAssistants(prev => [...prev, result.data]);
        setNewAssistant({
          name: '',
          featureType: 'IMAGE',
          features: []
        });
        setSaveStatus('Assistant configuration saved successfully');
      } else {
        throw new Error(result.message || 'Failed to save assistant configuration');
      }
    } catch (error) {
      logger.error('Error saving assistant:', error);
      setSaveStatus(error instanceof Error ? error.message : 'Failed to save assistant configuration');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteAssistant = async (assistantId: string) => {
    if (!window.confirm('Are you sure you want to delete this assistant?')) {
      return;
    }

    try {
      setIsLoading(true);
      
      const response = await fetch(`${API_BASE_URL}/assistants/${assistantId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to delete assistant');
      }

      setAssistants(prev => prev.filter(a => a.id !== assistantId));
    } catch (error) {
      logger.error('Error deleting assistant:', error);
      setError(error instanceof Error ? error.message : 'Failed to delete assistant');
    } finally {
      setIsLoading(false);
    }
  };

  // Effects
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const [featuresResponse, assistantsResponse, keysResponse] = await Promise.all([
          fetch(`${API_BASE_URL}/plan-features`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }),
          fetch(`${API_BASE_URL}/assistants`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }),
          fetch(`${API_BASE_URL}/keys`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })
        ]);

        if (!featuresResponse.ok || !assistantsResponse.ok || !keysResponse.ok) {
          throw new Error('Failed to fetch data');
        }

        const [featuresResult, assistantsResult, keysResult] = await Promise.all([
          featuresResponse.json(),
          assistantsResponse.json(),
          keysResponse.json()
        ]);

        if (featuresResult.data) {
          setPlanFeatures({
            ...featuresResult.data,
            supportedTypes: featuresResult.data.supportedTypes || ['IMAGE'],
            maxFeatures: featuresResult.data.maxFeatures || 1
          });
        }

        if (assistantsResult.data) {
          setAssistants(assistantsResult.data);
        }

        if (keysResult.data) {
          setApiKeys(keysResult.data);
        }
      } catch (err) {
        logger.error('Error fetching data:', err);
        setError(err instanceof Error ? err.message : 'An unknown error occurred');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token]);

  const renderFeatureTypeSelection = () => {
    if (!planFeatures) return null;

    return (
      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <Label>{t('dashboard.assistant.form.type')}</Label>
          <span className="text-sm text-muted-foreground">
            {t('dashboard.assistant.form.availableTypes', {
              plan: t(`plans.${planFeatures.planCode.toLowerCase()}.name`)
            })}
          </span>
        </div>

        <RadioGroup
          value={newAssistant.featureType}
          onValueChange={(value: FeatureType) => 
            setNewAssistant(prev => ({ ...prev, featureType: value }))}
          className="flex space-x-4"
        >
          {(['IMAGE', 'TEXT', 'AUDIO'] as const).map((type) => {
            const isAllowed = planFeatures.supportedTypes.includes(type);
            const typeId = `type-${type.toLowerCase()}`;
            
            return (
              <div key={typeId} className="flex items-center space-x-2">
                <RadioGroupItem 
                  value={type} 
                  id={typeId} 
                  disabled={!isAllowed}
                />
                <Label 
                  htmlFor={typeId} 
                  className={!isAllowed ? "text-muted-foreground cursor-not-allowed" : ""}
                >
                  {t(`dashboard.assistant.form.types.${type.toLowerCase()}`)}
                </Label>
              </div>
            );
          })}
        </RadioGroup>

        {!planFeatures.supportedTypes.includes(newAssistant.featureType) && (
          <Alert variant="warning">
            <AlertCircle className="h-4 w-4" />
            <AlertDescription>
              {t('dashboard.assistant.upgradePlan.description')}
            </AlertDescription>
          </Alert>
        )}
      </div>
    );
  };

  const renderDemoSection = (assistant: Assistant) => {
    const processedKeys = apiKeys
      .filter(key => key.status === 'ACTIVE' && key.key_hash)
      .map(key => ({
        id: key.id,
        name: key.name || `Key ${key.id.substring(0, 8)}`,
        keyHash: key.key_hash
      }));

    return (
      <AssistantDemo
        assistantId={assistant.id}
        availableKeys={processedKeys}
        features={assistant.features}
        featureType={assistant.featureType}
      />
    );
  };

  if (isLoading && !planFeatures) {
    return (
      <Card className="w-full">
        <CardContent className="p-6">
          <div className="text-center">{t('common.loading')}</div>
        </CardContent>
      </Card>
    );
  }

  if (error) {
    return (
      <Card className="w-full">
        <CardContent className="p-6">
          <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle>{t('dashboard.assistant.title')}</CardTitle>
        <CardDescription>
          {planFeatures && (
            <div className="mt-2">
              <p>{t('dashboard.assistant.description')}</p>
              <div className="mt-2 p-4 bg-secondary/10 rounded-md">
                <div className="font-medium">
                  {t(`plans.${planFeatures.planCode.toLowerCase()}.name`)}
                </div>
                <div className="text-sm text-muted-foreground space-y-1">
                  <div>
                    {t('dashboard.assistant.monthlyLimit', {
                      limit: planFeatures.monthlyApiLimit.toLocaleString()
                    })}
                  </div>
                  <div>
                    {t('dashboard.assistant.supportedTypes', {
                      types: planFeatures.supportedTypes
                        .map(type => t(`dashboard.assistant.form.types.${type.toLowerCase()}`))
                        .join(', ')
                    })}
                  </div>
                  <div>
                    {t('dashboard.assistant.maxFeatures', {
                      count: planFeatures.maxFeatures
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="space-y-6">
          <div>
            <Label htmlFor="assistant-name">{t('dashboard.assistant.form.name')}</Label>
            <Input
              id="assistant-name"
              value={newAssistant.name}
              onChange={(e) => setNewAssistant(prev => ({ ...prev, name: e.target.value }))}
              placeholder={t('dashboard.assistant.form.namePlaceholder')}
              disabled={isLoading}
              className="mt-1"
            />
          </div>

          {renderFeatureTypeSelection()}

          <div className="mt-6">
            <div className="space-y-6">
              <div>
                <h3 className="text-lg font-medium mb-4">
                  {t('dashboard.assistant.form.features')}
                </h3>
                <div className="text-sm text-muted-foreground mb-4">
                  {t('dashboard.assistant.form.featuresDescription')} 
                  ({newAssistant.features.length}/{planFeatures?.maxFeatures || 10})
                </div>


 <div className="p-4 border rounded-lg space-y-4">
                  <FeatureInput
                    onAdd={(feature) => {
                      if (newAssistant.features.length < (planFeatures?.maxFeatures || 10)) {
                        setNewAssistant(prev => ({
                          ...prev,
                          features: [...prev.features, feature]
                        }));
                      }
                    }}
                    disabled={isLoading || newAssistant.features.length >= (planFeatures?.maxFeatures || 10)}
                  />
                  
                  {newAssistant.features.length > 0 && (
                    <FeatureConfiguration
                      features={newAssistant.features}
                      onChange={(features) => setNewAssistant(prev => ({ ...prev, features }))}
                      disabled={isLoading}
                      maxFeatures={planFeatures?.maxFeatures}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          {saveStatus && (
            <Alert
              variant={saveStatus.includes('error') || saveStatus.includes('fail') || saveStatus.includes('Feature') ? 'destructive' : 'default'}
              className="mt-4 whitespace-pre-line"
            >
              <AlertDescription>{saveStatus}</AlertDescription>
            </Alert>
          )}

          <Button
            onClick={handleSaveAssistant}
            disabled={isLoading || !newAssistant.name.trim() || newAssistant.features.length === 0}
            className="w-full"
          >
            <Save className="h-4 w-4 mr-2" />
            {isLoading ? t('common.saving') : t('dashboard.assistant.form.save')}
          </Button>

          {assistants.length > 0 && (
            <div className="mt-6">
              <h3 className="text-lg font-medium mb-4">
                {t('dashboard.assistant.savedAssistants')}
              </h3>
              <div className="space-y-4">
                {assistants.map((assistant) => (
                  <div key={assistant.id}>
                    <div className="p-4 border rounded-lg bg-secondary/5 hover:bg-secondary/10 transition-colors">
                      <div className="space-y-4">
                        <div className="flex justify-between items-start">
                          <div>
                            <h4 className="font-medium">{assistant.name}</h4>
                            <div className="flex items-center gap-2 mt-1">
                              <span className="text-sm text-muted-foreground">ID:</span>
                              <div className="flex items-center gap-2">
                                <code className="px-2 py-1 bg-secondary rounded text-sm">
                                  {assistant.id}
                                </code>
                                <Button
                                  variant="ghost"
                                  size="sm"
                                  onClick={() => handleCopyId(assistant.id)}
                                  className="h-8 px-2 hover:bg-secondary"
                                  title={t('common.copyToClipboard')}
                                >
                                  {copiedId === assistant.id ? (
                                    <Check className="h-4 w-4 text-green-500" />
                                  ) : (
                                    <Copy className="h-4 w-4" />
                                  )}
                                </Button>
                              </div>
                            </div>
                          </div>
                          <Button
                            variant="ghost"
                            size="sm"
                            onClick={() => handleDeleteAssistant(assistant.id)}
                            disabled={isLoading}
                          >
                            <Trash2 className="h-4 w-4" />
                          </Button>
                        </div>

                        <div className="space-y-2">
                          <div className="flex items-center gap-2">
                            <span className="text-sm font-medium">
                              {t('dashboard.assistant.form.type')}:
                            </span>
                            <span className="text-sm">
                              {t(`dashboard.assistant.form.types.${assistant.featureType.toLowerCase()}`)}
                            </span>
                          </div>

                          <div>
                            <span className="text-sm font-medium">
                              {t('dashboard.assistant.features')}:
                            </span>
                            <ul className="mt-1 space-y-1">
                              {assistant.features.map((feature, idx) => (
                                <li
                                  key={`${assistant.id}-feature-${idx}`}
                                  className="text-sm text-muted-foreground ml-4 list-disc"
                                >
                                  <span className="font-medium">{feature.label}</span>
                                  <span className="ml-2">({feature.description})</span>
                                  <span className="text-xs ml-2 text-muted-foreground">
                                    {feature.outputDefinition.outputType}
                                    {feature.outputDefinition.required ? ', required' : ''}
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </div>

                          {assistant.updatedAt && (
                            <div className="text-xs text-muted-foreground">
                              {t('dashboard.assistant.lastUpdated')}: {
                                new Date(assistant.updatedAt).toLocaleDateString(
                                  undefined,
                                  {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                  }
                                )
                              }
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {renderDemoSection(assistant)}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default AssistantConfiguration;
