import React from 'react';
import { Card } from '@/components/ui/card';
import { useTranslation } from 'react-i18next';
import type { Feature } from '@/types/dashboard';

interface PreviewFormatProps {
  features: Feature[];
}

const PreviewFormat: React.FC<PreviewFormatProps> = ({ features }) => {
  const { t } = useTranslation();

  const generateSampleOutput = () => {
    const output: Record<string, unknown> = {};

    features.forEach(feature => {
      if (!feature.label) return;
      
      let sampleValue: unknown = feature.outputDefinition?.sampleValue;

      // Convert sample value based on output type
      if (feature.outputDefinition) {
        switch (feature.outputDefinition.outputType) {
          case 'string':
            sampleValue = sampleValue || 'sample text';
            break;
          case 'number':
            sampleValue = sampleValue ? Number(sampleValue) : 42;
            break;
          case 'boolean':
            sampleValue = sampleValue ? sampleValue === 'true' : true;
            break;
          case 'array':
            if (!sampleValue) {
              sampleValue = ['item1', 'item2'];
            } else if (typeof sampleValue === 'string') {
              try {
                sampleValue = JSON.parse(sampleValue);
              } catch {
                sampleValue = sampleValue.split(',').map(item => item.trim());
              }
            }
            break;
          default:
            sampleValue = null;
        }
      }

      // Use feature.label instead of feature.description for JSON key
      output[feature.label] = sampleValue;
    });

    return {
      success: true,
      data: output
    };
  };

  return (
    <Card className="p-4 bg-muted/50">
      <div className="space-y-2">
        <h4 className="text-sm font-medium">
          {t('dashboard.assistant.preview.title')}
        </h4>
        <pre className="p-2 bg-background rounded-md overflow-x-auto">
          <code>
            {JSON.stringify(generateSampleOutput(), null, 2)}
          </code>
        </pre>
        <p className="text-xs text-muted-foreground">
          {t('dashboard.assistant.preview.description')}
        </p>
      </div>
    </Card>
  );
};

export default PreviewFormat;
