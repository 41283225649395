import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Code } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import type { Feature } from '@/types/dashboard';
import PreviewFormat from './PreviewFormat';
import FeatureDefinitionForm from './FeatureDefinitionForm';

interface FeatureConfigurationProps {
  features: Feature[];
  onChange: (features: Feature[]) => void;
  disabled?: boolean;
  maxFeatures?: number;
}

const FeatureConfiguration: React.FC<FeatureConfigurationProps> = ({
  features,
  onChange,
  disabled,
  maxFeatures = 10
}) => {
  const { t } = useTranslation();
  const [showPreview, setShowPreview] = useState(false);

  const handleFeatureChange = (index: number, updatedFeature: Feature) => {
    const newFeatures = [...features];
    newFeatures[index] = {
      ...updatedFeature,
      outputDefinition: {
        ...updatedFeature.outputDefinition,
        description: updatedFeature.description
      }
    };
    
    onChange(newFeatures);
  };

  const handleFeatureRemove = (index: number) => {
    onChange(features.filter((_, i) => i !== index));
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <div className="space-y-1">
          <Label>{t('dashboard.assistant.form.features')}</Label>
          <p className="text-sm text-muted-foreground">
            {t('dashboard.assistant.form.featuresDescription')}
          </p>
        </div>
        <Button
          variant="outline"
          size="sm"
          onClick={() => setShowPreview(!showPreview)}
        >
          <Code className="h-4 w-4 mr-2" />
          {showPreview ? t('common.hide') : t('common.show')} {t('dashboard.assistant.preview.format')}
        </Button>
      </div>

      {showPreview && <PreviewFormat features={features} />}

      <div className="space-y-4">
        {features.map((feature, index) => (
          <FeatureDefinitionForm
            key={feature.id || index}
            feature={feature}
            onChange={(updated) => handleFeatureChange(index, updated)}
            onRemove={() => handleFeatureRemove(index)}
            disabled={disabled}
          />
        ))}
      </div>
    </div>
  );
};

export default FeatureConfiguration;
