import React from 'react';
import { Switch as HeadlessSwitch } from '@headlessui/react';
import { cn } from "@/lib/utils";

interface SwitchProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
  className?: string;
}

const Switch = React.forwardRef<HTMLButtonElement, SwitchProps>(
  ({ checked, onChange, disabled = false, className }, ref) => {
    return (
      <HeadlessSwitch
        ref={ref}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        className={cn(
          "relative inline-flex h-6 w-11 items-center rounded-full",
          checked ? "bg-primary" : "bg-gray-200",
          disabled && "opacity-50 cursor-not-allowed",
          className
        )}
      >
        <span className="sr-only">Toggle switch</span>
        <span
          className={cn(
            "inline-block h-4 w-4 transform rounded-full bg-white transition",
            checked ? "translate-x-6" : "translate-x-1"
          )}
        />
      </HeadlessSwitch>
    );
  }
);

Switch.displayName = 'Switch';

export { Switch };
