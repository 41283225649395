// src/components/dashboard/UsageMetrics.tsx

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Clock, Activity, RefreshCw } from 'lucide-react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Alert, AlertDescription } from "@/components/ui/alert";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useAuth } from '../../hooks/useAuth';
import { logger } from '../../utils/logger';
import { UsageMetrics as UsageMetricsType, TimeframeOption } from '../../types/dashboard';

const UsageMetrics: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { token } = useAuth();
  const [timeframe, setTimeframe] = useState<TimeframeOption>('month');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [usageData, setUsageData] = useState<UsageMetricsType | null>(null);
  const [refreshKey, setRefreshKey] = useState(0);

  const formatDate = (dateString: string, format: 'short' | 'long' = 'short') => {
    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        throw new Error('Invalid date');
      }

      if (format === 'short') {
        return new Intl.DateTimeFormat(i18n.language, {
          month: 'numeric',
          day: 'numeric'
        }).format(date);
      }

      return new Intl.DateTimeFormat(i18n.language, {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      }).format(date);
    } catch (error) {
      logger.error('Error formatting date:', error);
      return dateString;
    }
  };

  const fetchUsageMetrics = async () => {
    try {
      setLoading(true);
      setError(null);

      const response = await fetch(`/api/dashboard/usage/metrics?timeframe=${timeframe}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || t('dashboard.usage.errors.fetchFailed'));
      }

      const data = await response.json();
      if (!data.success || !data.data) {
        throw new Error(t('dashboard.usage.errors.fetchFailed'));
      }

      setUsageData(data.data);
    } catch (error) {
      logger.error('Error fetching usage metrics:', error);
      setError(error instanceof Error ? error.message : t('dashboard.usage.errors.unknown'));
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = () => {
    setRefreshKey(prev => prev + 1);
  };

  useEffect(() => {
    fetchUsageMetrics();
  }, [timeframe, token, refreshKey, i18n.language]);

  if (loading && !usageData) {
    return (
      <div className="flex items-center justify-center p-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (!usageData) {
    return (
      <Alert variant="destructive">
        <AlertDescription>{error || t('dashboard.usage.errors.noData')}</AlertDescription>
      </Alert>
    );
  }

  const usagePercentage = (usageData.currentUsage / usageData.monthlyLimit) * 100;
  const isNearLimit = usagePercentage >= 80;
  const isOverLimit = usagePercentage >= 100;

  return (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between pb-2">
        <div className="space-y-1">
          <CardTitle>{t('dashboard.usage.title')}</CardTitle>
          <CardDescription>{t('dashboard.usage.description')}</CardDescription>
        </div>
        <div className="flex items-center gap-4">
          <Select
            value={timeframe}
            onValueChange={(value: TimeframeOption) => setTimeframe(value)}
          >
            <SelectTrigger className="w-[180px]">
              <Clock className="mr-2 h-4 w-4" />
              <SelectValue placeholder={t('dashboard.usage.selectTimeframe')} />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="day">{t('dashboard.usage.timeframes.day')}</SelectItem>
              <SelectItem value="week">{t('dashboard.usage.timeframes.week')}</SelectItem>
              <SelectItem value="month">{t('dashboard.usage.timeframes.month')}</SelectItem>
            </SelectContent>
          </Select>
          <Button
            variant="outline"
            size="icon"
            onClick={handleRefresh}
            disabled={loading}
          >
            <RefreshCw className={`h-4 w-4 ${loading ? 'animate-spin' : ''}`} />
          </Button>
        </div>
      </CardHeader>
      <CardContent>
        <div className="space-y-8">
          {/* Usage Progress */}
          <div className="space-y-2">
            <div className="flex items-center justify-between">
              <span className="text-sm font-medium">
                {t('dashboard.usage.currentUsage', {
                  used: usageData.currentUsage.toLocaleString(i18n.language),
                  total: usageData.monthlyLimit.toLocaleString(i18n.language)
                })}
              </span>
              <span className={`text-sm font-medium ${
                isOverLimit ? 'text-destructive' : 
                isNearLimit ? 'text-warning' : 'text-muted-foreground'
              }`}>
                {usagePercentage.toFixed(1)}%
              </span>
            </div>
            <div className="h-2 w-full bg-secondary rounded-full overflow-hidden">
              <div
                className={`h-full ${
                  isOverLimit ? 'bg-destructive' : 
                  isNearLimit ? 'bg-warning' : 'bg-primary'
                }`}
                style={{ width: `${Math.min(usagePercentage, 100)}%` }}
              />
            </div>
          </div>

          {/* Usage Chart */}
          {usageData?.usageByDay?.length > 0 ? (
            <div className="h-[300px]">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  data={usageData.usageByDay}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="date"
                    tick={{ fontSize: 12 }}
                    tickFormatter={(value) => formatDate(value)}
                  />
                  <YAxis tick={{ fontSize: 12 }} />
                  <Tooltip
                    labelFormatter={(value) => formatDate(value, 'long')}
                    formatter={(value: number) => [
                      value.toLocaleString(i18n.language),
                      t('dashboard.usage.calls')
                    ]}
                    contentStyle={{
                      backgroundColor: 'rgba(0, 0, 0, 0.85)',
                      border: 'none',
                      borderRadius: '4px',
                      color: 'white',
                      padding: '8px'
                    }}
                  />
                  <Line
                    type="monotone"
                    dataKey="count"
                    stroke="#3b82f6"
                    strokeWidth={2}
                    dot={{ r: 4, strokeWidth: 2 }}
                    activeDot={{ r: 6 }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          ) : (
            <div className="text-center py-8 text-muted-foreground">
              {t('dashboard.usage.noData')}
            </div>
          )}
        </div>

        {error && (
          <Alert variant="destructive" className="mt-4">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
      </CardContent>
    </Card>
  );
};

export default UsageMetrics;
